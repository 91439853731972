import { Button } from '@amway/react-components';
import { FormControlLabel, Switch } from '@mui/material';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Features } from '../../../../config/features';
import { WithFeaturesProxy } from '../../../../helpers/with-features-proxy';
import { ExecutionRequest, ExecutionStatus } from '../../../../resources/history-list/history-list-types';
import useMetrics from '../../../../resources/metrics/metrics-hook';
import useProcessors from '../../../../resources/processors/processors-hook';
import adminService from '../../../../service/admin.service';
import { addDays, subtractDays, toISOString } from '../../../../utils/date-utils';
import AsyncAutocompleteComponent from '../../../ui/async-autocomplete';
import DateRangeFieldsComponent from '../../../ui/date-range-fields';
import { Item } from '../../../ui/dropdown-btn';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown';
import SearchInputComponent from '../../../ui/search-input';
import './index.scss';

export interface Filters {
  searchText: string;
  startDate: string;
  endDate: string;
  reprocessedEvents: boolean;
  userId?: string;
  processor?: string[];
  status?: ExecutionStatus[];
  country?: string[];
}

interface ExposedProps {
  filters?: Filters;
  onFiltersChange?: (newFilters: Filters) => void;
  onSubmit: (request: ExecutionRequest) => void;
  submitOnLoad?: boolean;
  availableStatus?: ExecutionStatus[];
  daysAgo?: number;
  daysLater?: number;
  daysRange?: number;
  displayExcludeReprocessedEvents?: boolean;
}

interface Props extends ExposedProps {
  mktHasSharedId: boolean;
  mktHasUserId: boolean;
  mktHasMultipleCountry: boolean;
}

function ExecutionHistoryFormComponent({
  onSubmit,
  submitOnLoad,
  filters,
  onFiltersChange,
  availableStatus = Object.values(ExecutionStatus),
  daysRange = 7,
  daysAgo = 2,
  daysLater = 1,
  displayExcludeReprocessedEvents = false,
  mktHasSharedId,
  mktHasUserId,
  mktHasMultipleCountry,
}: Props) {
  const { processors: processorsData, fetchProcessors } = useProcessors();
  const { allCountries, fetchAllCountries } = useMetrics();
  const processors = useMemo(
    () => (processorsData.data ?? []).map(({ name }) => ({ name, value: name })),
    [processorsData.data],
  );
  const availableStatuses = useMemo(
    () => availableStatus.map(statusName => ({ name: statusName, value: statusName })),
    [availableStatus],
  );
  const [usersOptions, setUsersOptions] = useState<Item[]>([]);
  const [startDate, setStartDate] = useState<string>(
    filters?.startDate ? filters?.startDate : toISOString(subtractDays(new Date(), daysAgo)),
  );
  const [maxDate, setMaxDate] = useState<string>(toISOString(addDays(new Date(), daysLater)));
  const [endDate, setEndDate] = useState<string>(filters?.endDate ? filters?.endDate : maxDate);
  const [searchText, setSearchText] = useState<string>(filters?.searchText ?? '');
  const [selectedUser, setSelectedUser] = useState<string | undefined>(filters?.userId);
  const [excludeReprocessedEvents, setExcludeReprocessedEvents] = useState<boolean>(
    filters ? filters.reprocessedEvents : true,
  );
  const [selectedStatus, setSelectedStatus] = useState<string[] | undefined>(filters?.status);
  const [selectedProcessor, setSelectedProcessor] = useState<string[] | undefined>(filters?.processor);
  const [selectedCountry, setSelectedCountry] = useState<string[] | undefined>(filters?.country);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [exceedsDaysRange, setExceedsDaysRange] = useState<boolean>(false);
  const [searchInvalidMessage, setSearchInvalidMessage] = useState<string | null | undefined>();
  const [hasSubmittedOnLoad, setHasSubmittedOnLoad] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout>();
  const selectedUserItem = useMemo(
    () => (selectedUser ? usersOptions.find(it => it.id === selectedUser) ?? null : null),
    [selectedUser, usersOptions],
  );

  useEffect(() => {
    adminService
      .getUsers('')
      .then(users =>
        users.map(
          user =>
            ({
              id: user.username,
              label: user.name,
              thirdLabel: user.email,
            } as Item),
        ),
      )
      .then(data => setUsersOptions(data));
  }, []);

  useEffect(() => {
    fetchProcessors();
  }, [fetchProcessors]);

  useEffect(() => {
    fetchAllCountries();
  }, [fetchAllCountries]);

  useEffect(() => {
    if (filters) {
      setStartDate(filters.startDate);
      setEndDate(filters.endDate);
      setSearchText(filters.searchText);
      setSelectedUser(filters.userId);
      setExcludeReprocessedEvents(filters.reprocessedEvents);
      setSelectedStatus(filters.status);
      setSelectedProcessor(filters.processor);
      setSelectedCountry(filters.country);
    }
  }, [filters]);

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange({
        startDate,
        endDate,
        searchText,
        userId: selectedUser,
        reprocessedEvents: displayExcludeReprocessedEvents ? !excludeReprocessedEvents : false,
        status: selectedStatus as ExecutionStatus[],
        processor: selectedProcessor,
        country: selectedCountry,
      });
    }
  }, [
    onFiltersChange,
    endDate,
    excludeReprocessedEvents,
    searchText,
    selectedProcessor,
    selectedCountry,
    selectedStatus,
    selectedUser,
    startDate,
    displayExcludeReprocessedEvents,
  ]);

  const countryOptions = useMemo(() => {
    if (allCountries.data && allCountries.data !== undefined) {
      return allCountries.data
        .filter(country => country !== null)
        .map(country => ({ value: country.toString(), name: country.toString() }));
    }
    return [];
  }, [allCountries.data]);

  const handleUserSearch = useCallback(
    (usernameOrEmail: string) => {
      return Promise.resolve(
        usernameOrEmail !== ''
          ? usersOptions.filter(
              userOption =>
                userOption.label.toLowerCase().startsWith(usernameOrEmail.toLowerCase()) ||
                userOption.thirdLabel?.toLowerCase()?.startsWith(usernameOrEmail.toLowerCase()),
            )
          : usersOptions,
      );
    },
    [usersOptions],
  );

  const handleToggleExcludeReprocessedEvents = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setExcludeReprocessedEvents(event.target.checked);
  }, []);

  const formValues = useMemo(
    () => ({
      searchText,
      processor: selectedProcessor,
      status: selectedStatus as ExecutionStatus[],
      country: selectedCountry,
      startDate: startDate ? startDate + 'T00:00:00.000' : undefined,
      endDate: endDate ? endDate + 'T23:59:59.999' : undefined,
      reprocessedEvents: displayExcludeReprocessedEvents ? !excludeReprocessedEvents : undefined,
      userId: selectedUser,
    }),
    [
      displayExcludeReprocessedEvents,
      endDate,
      excludeReprocessedEvents,
      searchText,
      selectedCountry,
      selectedProcessor,
      selectedStatus,
      selectedUser,
      startDate,
    ],
  );

  const submit = useCallback(() => {
    if (!invalid) {
      onSubmit(formValues);
    }
  }, [invalid, onSubmit, formValues]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submit();
    },
    [submit],
  );

  useEffect(() => {
    if (searchText.includes('%') || searchText.includes('"') || searchText.includes('#')) {
      setInvalid(true);
      setSearchInvalidMessage('%, ", # are non-valid characters');
    } else {
      setInvalid(false);
      setSearchInvalidMessage(null);
    }
  }, [searchText]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setMaxDate(toISOString(addDays(new Date(), daysLater)));
    }, 60000);

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [daysLater]);

  useEffect(() => {
    if (submitOnLoad && !hasSubmittedOnLoad) {
      submit();
      setHasSubmittedOnLoad(true);
    }
  }, [hasSubmittedOnLoad, submitOnLoad, submit]);

  const handleCountryChanges = useCallback((newCountries?: string[]) => {
    if (newCountries && newCountries.length > 0) setSelectedCountry(newCountries);
    else setSelectedCountry(undefined);
  }, []);

  const handleProcessorChanges = useCallback((newProcessors?: string[]) => {
    if (newProcessors && newProcessors.length > 0) setSelectedProcessor(newProcessors);
    else setSelectedProcessor(undefined);
  }, []);

  const handleStatusesChanges = useCallback((newStatuses?: string[]) => {
    if (newStatuses && newStatuses.length > 0) setSelectedStatus(newStatuses);
    else setSelectedStatus(undefined);
  }, []);

  const handleSelectProcessorFieldSize = useMemo(() => {
    if (mktHasMultipleCountry) {
      return {
        sm: 12,
        md: 8,
        lg: 8,
        xl: 4,
        xxl: 6,
      };
    }
    return {
      sm: 12,
      md: 12,
      lg: 8,
      xl: 6,
      xxl: 8,
    };
  }, [mktHasMultipleCountry]);

  return (
    <Form className="execution-history-form" onSubmit={handleSubmit}>
      <Row>
        <DateRangeFieldsComponent
          sm={12}
          md={6}
          lg={3}
          xl={3}
          xxl={2}
          styleType="filter"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          maxDate={maxDate}
          daysRange={daysRange}
          exceedsDaysRange={exceedsDaysRange}
          setExceedsDaysRange={setExceedsDaysRange}
        />
        {mktHasMultipleCountry && (
          <Col sm={12} md={4} lg={6} xl={2} xxl={2}>
            <MultiSelectDropdown
              id="countries-dropdown"
              label="Country"
              placeholder="Country"
              variant="secondary"
              all
              items={countryOptions}
              value={selectedCountry}
              onChange={handleCountryChanges}
            />
          </Col>
        )}
        <Col {...handleSelectProcessorFieldSize}>
          <MultiSelectDropdown
            id="processor-dropdown"
            label="Processor"
            placeholder="Processor"
            variant="secondary"
            all
            items={processors}
            value={selectedProcessor}
            onChange={handleProcessorChanges}
          />
        </Col>
        <Col sm={12} md={4} lg={4} xl={3} xxl={2}>
          <MultiSelectDropdown
            id="status-dropdown"
            label="Status"
            placeholder="Status"
            variant="secondary"
            all
            items={availableStatuses}
            value={selectedStatus}
            onChange={handleStatusesChanges}
          />
        </Col>
        {mktHasUserId && (
          <Col sm={12} md={8} lg={12} xl={9} xxl={4}>
            <AsyncAutocompleteComponent
              id="user-async-autocomplete"
              label="User"
              placeholder="Search User ID or email"
              styleType="filter"
              items={usersOptions}
              search={handleUserSearch}
              selectedValue={selectedUserItem}
              selectValue={item => setSelectedUser(item?.id as string)}
            />
          </Col>
        )}
        <Col sm={12} md={12} lg={8} xl={7} xxl={4} className="search-input">
          <SearchInputComponent
            id="search-execution-input"
            label="Search"
            placeholder={
              mktHasSharedId
                ? 'Search Execution ID, Message ID, Shared ID, Input...'
                : 'Search Execution ID, Message ID, Input...'
            }
            isInvalid={invalid}
            feedbackMessage={searchInvalidMessage}
            value={searchText}
            onChange={setSearchText}
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={4}
          xl={2}
          xxl={2}
          className="submit d-flex mt-1 mb-4 justify-content-center align-content-center align-items-end">
          <Button disabled={invalid || exceedsDaysRange}>SEARCH</Button>
        </Col>
        {displayExcludeReprocessedEvents && (
          <Col>
            <FormControlLabel
              value="false"
              control={
                <Switch
                  color="success"
                  checked={excludeReprocessedEvents}
                  onChange={handleToggleExcludeReprocessedEvents}
                />
              }
              label="Exclude reprocessed events"
              labelPlacement="end"
            />
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default WithFeaturesProxy<ExposedProps>(
  Features.MktFeature_HasSharedId,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
  Features.MktFeature_HasMultipleCountry,
)((props, mktHasSharedId, mktHasUserId, mktHasMultipleCountry) => {
  return (
    <ExecutionHistoryFormComponent
      {...props}
      mktHasSharedId={mktHasSharedId}
      mktHasUserId={mktHasUserId}
      mktHasMultipleCountry={mktHasMultipleCountry}
    />
  );
});
