export enum Features {
  ManageOwnPermissions = 'ManageOwnPermissions',
  DashboardScreen = 'DashboardScreen',
  ExecutionHistoryScreen = 'ExecutionHistoryScreen',
  ExecutionHistoryScreen_Details = 'ExecutionHistoryScreen_Details',
  ExceptionHistoryScreen = 'ExceptionHistoryScreen',
  ExceptionHistoryScreen_Details = 'ExceptionHistoryScreen_Details',
  ReportsScreen = 'ReportsScreen',
  CompareEmailsScreen = 'CompareEmailsScreen',
  CompareEmail = 'CompareEmail',
  TestingToolScreen = 'TestingToolScreen',
  ReprocessEmail = 'ReprocessEmail',
  AllowListScreen = 'AllowListScreen',
  MktFeature_EmailPreview = 'MktFeature_EmailPreview',
  MktFeature_ShowUserIdOnExecutionDetails = 'MktFeature_ShowUserIdOnExecutionDetails',
  MktFeature_ReprocessExecution = 'MktFeature_ReprocessExecution',
  MktFeature_ResendEmail = 'MktFeature_ResendEmail',
  MktFeature_HasSharedId = 'MktFeature_HasSharedId',
  MktFeature_LanguageSelectorOnTestingTool = 'MktFeature_LanguageSelectorOnTestingTool',
  MktFeature_UsesPublishAPI = 'MktFeature_UsesPublishAPI',
  MktFeature_HasMultipleCountry = 'MktFeature_HasMultipleCountry',
  MktFeature_Health_AmwayProfile = 'MktFeature_Health_AmwayProfile',
  MktFeature_Health_Exponea = 'MktFeature_Health_Exponea',
  MktFeature_Health_OrderLookupAPI = 'MktFeature_Health_OrderLookupAPI',
  MktFeature_Health_ProductInfoAPI = 'MktFeature_Health_ProductInfoAPI',
  MktFeature_Health_VolumeAPI = 'MktFeature_Health_VolumeAPI',
  MktFeature_Health_Uplines = 'MktFeature_Health_Uplines',
  MktFeature_Health_Docgen = 'MktFeature_Health_Docgen',
  MktFeature_Health_PublisherAPI = 'MktFeature_Health_PublisherAPI',
}
