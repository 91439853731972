import HistoryListProvider from '../resources/history-list/history-list-provider';
import AllowListComponent from '../screens/allow-list';
import CompareEmailsComponent from '../screens/compare-emails';
import DashboardComponent from '../screens/dashboard';
import ExceptionHistoryComponent from '../screens/exception-history';
import ExecutionHistoryComponent from '../screens/execution-history';
import ExecutionHistoryDetails from '../screens/execution-history/execution-history-details';
import ReportsComponent from '../screens/reports';
import TestingToolComponent from '../screens/testing-tool';
import { Features } from './features';

export interface RouteData {
  label: string;
  path: string;
  feature: Features;
  /** If this is undefined, we have an unrestricted route */
  element: JSX.Element;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    feature: Features.DashboardScreen,
    element: <DashboardComponent />,
  },
  {
    label: 'Testing tool',
    path: '/testing-tool',
    feature: Features.TestingToolScreen,
    element: <TestingToolComponent />,
  },
  {
    label: 'Execution details',
    path: '/execution-history/details',
    feature: Features.ExceptionHistoryScreen_Details,
    element: (
      <HistoryListProvider>
        <ExecutionHistoryDetails />
      </HistoryListProvider>
    ),
  },
  {
    label: 'Execution history',
    path: '/execution-history',
    feature: Features.ExecutionHistoryScreen,
    element: (
      <HistoryListProvider>
        <ExecutionHistoryComponent />
      </HistoryListProvider>
    ),
  },
  {
    label: 'Exception history',
    path: '/exception-history',
    feature: Features.ExceptionHistoryScreen,
    element: (
      <HistoryListProvider>
        <ExceptionHistoryComponent />
      </HistoryListProvider>
    ),
  },
  {
    label: 'Reports Screen',
    path: '/reports',
    feature: Features.ReportsScreen,
    element: <ReportsComponent />,
  },
  {
    label: 'Compare emails',
    path: '/compare-emails',
    feature: Features.CompareEmailsScreen,
    element: <CompareEmailsComponent />,
  },
  {
    label: 'Allow list',
    path: '/allow-list',
    feature: Features.AllowListScreen,
    element: <AllowListComponent />,
  },
];
