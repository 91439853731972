import { BrowserRouter } from 'react-router-dom';
import AllowListProvider from './resources/allow-list/allow-list-provider';
import AuthProvider from './resources/auth/auth-provider';
import ExecutionProvider from './resources/executions/executions-provider';
import FeaturesProvider from './resources/features/features-provider';
import MetricsProvider from './resources/metrics/metrics-provider';
import ProcessorsProvider from './resources/processors/processors-provider';
import SystemsHealthProvider from './resources/systems-health/systems-health-provider';
import ToastsProvider from './resources/toasts/toasts-provider';
import Router from './router';

export default function App() {
  return (
    <BrowserRouter>
      <ToastsProvider>
        <AuthProvider>
          <FeaturesProvider>
            <SystemsHealthProvider>
              <AllowListProvider>
                <MetricsProvider>
                  <ProcessorsProvider>
                    <ExecutionProvider>
                      <Router />
                    </ExecutionProvider>
                  </ProcessorsProvider>
                </MetricsProvider>
              </AllowListProvider>
            </SystemsHealthProvider>
          </FeaturesProvider>
        </AuthProvider>
      </ToastsProvider>
    </BrowserRouter>
  );
}
