import { SidebarNav } from '@amway/react-components';
import { useMemo } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { BsBarChart } from 'react-icons/bs';
import { FiAlertCircle } from 'react-icons/fi';
import { MdOutlineSpeed } from 'react-icons/md';
import { PiClockCounterClockwiseFill } from 'react-icons/pi';
import { RiPlayListAddFill, RiTestTubeFill } from 'react-icons/ri';
import { authenticatedRoutes } from '../../../config/routes';
import { FeaturesCtxType } from '../../../resources/features/features-context';
import useFeatures from '../../../resources/features/features-hook';

const links = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    eventKey: 'dashboard',
    IconComponent: MdOutlineSpeed,
  },
  {
    to: '/execution-history',
    label: 'Execution History',
    eventKey: 'execution-history',
    IconComponent: PiClockCounterClockwiseFill,
  },
  {
    to: '/exception-history',
    label: 'Exception History',
    eventKey: 'exception-history',
    IconComponent: FiAlertCircle,
  },
  {
    to: '/reports',
    label: 'Reports',
    eventKey: 'reports',
    IconComponent: AiOutlineFile,
  },
  {
    to: '/testing-tool',
    label: 'Testing Tool',
    eventKey: 'testing-tool',
    IconComponent: RiTestTubeFill,
  },
  {
    to: '/compare-emails',
    label: 'Compare Emails',
    eventKey: 'compare-emails',
    IconComponent: BsBarChart,
  },
  {
    to: '/allow-list',
    label: 'Allow List',
    eventKey: 'allow-list',
    IconComponent: RiPlayListAddFill,
  },
];

const filterAllowedLinks = (hasFeature: FeaturesCtxType['hasFeature']) => {
  return links.filter(link => {
    const linkRoute = authenticatedRoutes.find(route => route.path === link.to)!;

    return hasFeature(linkRoute.feature);
  });
};

interface Props {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
}

export default function SideNavComponent({ open, toggleMenu, onLogout }: Props) {
  const { hasFeature } = useFeatures();
  const allowedLinks = useMemo(() => filterAllowedLinks(hasFeature), [hasFeature]);

  return <SidebarNav open={open} toggleMenu={toggleMenu} onLogout={onLogout} allowedLinks={allowedLinks} />;
}
